import styled, { css } from 'styled-components';

export const Input = styled.textarea`
  border: none;
  caret-color: ${({ theme }) => theme.colors.black};
  width: 100%;
  font-size: 4.8rem;
  font-weight: 600;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: lightgrey;
  }

  ${({ isEditing }) => css`
    /* border: 2px solid green; */
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-top: 30px;
    resize: none;
    font-family: "Inter", Helvetica, Arial, sans-serif;
  `}
`;
